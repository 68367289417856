import React from 'react';

import HomeHeader from '../components/HomeHeader';
import Layout from '../components/Layout';

import styles from '../styles/404.module.scss';

export default function Page404() {
    return (
        <Layout header={<HomeHeader />} title="404 | Micromobility Dashboard">
            <div className={styles.container}>
                <h1 className={styles.errorCode}>404</h1>
                <p>Route not found</p>
            </div>
        </Layout>
    );
}
